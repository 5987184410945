import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Description`}</h4>
    <p>{`While working at `}<ExternalLink href="https://www.geosolutionsgroup.com/" mdxType="ExternalLink">{`GeoSolutions`}</ExternalLink>{` I was part of the team that developed the
Traffic Density Mapping Service (TDMS) for the European Maritime Safety  Agency (EMSA). This service generated
traffic density maps for the european seas in a fully automated and periodic schedule.`}</p>
    <h4>{`My role`}</h4>
    <p>{`My role on this project was the design and implementation of the processing
and visualization components, which consisted of`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A set of automated processing lines implemented with apache airflow and running
on a distributed cluster of worker nodes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A set of algorithms to`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Extract ship instantaneous positions from EMSA's oracle database`}</li>
          <li parentName="ul">{`Generate routes for each ship and aggregate routes according to predefined categories`}</li>
          <li parentName="ul">{`Calculate traffic density according to EMSA-supplied methodology`}</li>
          <li parentName="ul">{`Perform temporal aggregation of results`}</li>
          <li parentName="ul">{`Publish results to GeoServer  `}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Distributing of the final products via WMS using GeoServer, by using its REST API.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      